import Loader from "components/Loader";
import React from "react";

const NoWeb3BrowserModal = React.lazy(() => import("./components/NoWeb3BrowserModal"));
const NoWalletModal = React.lazy(() => import("./components/NoWalletModal"));
const WrongNetworkModal = React.lazy(() => import("./components/WrongNetworkModal"));
const ConnectionModal = React.lazy(() => import("./components/ConnectionModal"));
const TransactionConnectionModal = React.lazy(() => import("./components/TransactionConnectionModal"));
const ConnectionPendingModal = React.lazy(() => import("./components/ConnectionPendingModal"));
const UserRejectedConnectionModal = React.lazy(() => import("./components/UserRejectedConnectionModal"));
const ValidationPendingModal = React.lazy(() => import("./components/ValidationPendingModal"));
const UserRejectedValidationModal = React.lazy(() => import("./components/UserRejectedValidationModal"));
const LowFundsModal = React.lazy(() => import("./components/LowFundsModal"));
const SignatureRequestModal = React.lazy(() => import("./components/SignatureRequestModal"));


class ConnectionModalUtil extends React.Component {

  render() {

    const {
      noWeb3BrowserModalIsOpen,
      noWalletModalIsOpen,
      wrongNetworkModalIsOpen,
      connectionModalIsOpen,
      transactionConnectionModalIsOpen,
      accountConnectionPending,
      accountSignatureRequest,
      userRejectedConnect,
      accountValidationPending,
      userRejectedValidation,
      lowFundsModalIsOpen,
    } = this.props.modals.data;

    const {
      closeNoWeb3BrowserModal,
      closeNoWalletModal,
      closeWrongNetworkModal,
      closeConnectionModal,
      closeTransactionConnectionModal,
      closeConnectionPendingModal,
      closeSignatureRequestModal,
      closeUserRejectedConnectionModal,
      closeValidationPendingModal,
      closeUserRejectedValidationModal,
      closeLowFundsModal,
    } = this.props.modals.methods;


    const { account, network, transaction } = this.props;


    return (
      <React.Suspense fallback={<Loader className="fixed"/>}>
        {noWeb3BrowserModalIsOpen && (
          <NoWeb3BrowserModal
            closeModal={closeNoWeb3BrowserModal}
            isOpen={noWeb3BrowserModalIsOpen}
            transaction={transaction}
          />
        )}
        {noWalletModalIsOpen && (
          <NoWalletModal
            closeModal={closeNoWalletModal}
            isOpen={noWalletModalIsOpen}
            transaction={transaction}
          />
        )
        }
        {wrongNetworkModalIsOpen && (
          <WrongNetworkModal
            closeModal={closeWrongNetworkModal}
            isOpen={wrongNetworkModalIsOpen}
            network={network}
            networkRequired={this.props.networkRequired}
          />
        )}

        {connectionModalIsOpen && !this.props.accountValidated && (
          <ConnectionModal
            closeModal={closeConnectionModal}
            isOpen={connectionModalIsOpen && !this.props.accountValidated}
            currentNetwork={network}
          />
        )}
        {transactionConnectionModalIsOpen && (
          <TransactionConnectionModal
            closeModal={closeTransactionConnectionModal}
            isOpen={transactionConnectionModalIsOpen}
            currentNetwork={network}
          />

        )}
        {accountConnectionPending && (
          <ConnectionPendingModal
            closeModal={closeConnectionPendingModal}
            isOpen={accountConnectionPending}
            currentNetwork={network}
          />

        )}
        {accountSignatureRequest && (
          <SignatureRequestModal
            closeModal={closeSignatureRequestModal}
            isOpen={accountSignatureRequest}
          />
        )}
        {userRejectedConnect && (
          <UserRejectedConnectionModal
            closeModal={closeUserRejectedConnectionModal}
            isOpen={userRejectedConnect}
            loginAccount={this.props.loginAccount}
          />
        )}
        {accountValidationPending && (
          <ValidationPendingModal
            closeModal={closeValidationPendingModal}
            isOpen={accountValidationPending}
            currentNetwork={network}
            account={account}
          />
        )}
        {userRejectedValidation && (
          <UserRejectedValidationModal
            closeModal={closeUserRejectedValidationModal}
            isOpen={userRejectedValidation}
          />
        )}
        {lowFundsModalIsOpen && (
          <LowFundsModal
            closeModal={closeLowFundsModal}
            isOpen={lowFundsModalIsOpen}
            currentNetwork={network}
            account={account}
          />
        )}
      </React.Suspense>
    );
  }
}

export default ConnectionModalUtil;
